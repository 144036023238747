import { ENV } from '@/common/env';

const { version } = require('../../package.json');

export const AUTH_ENABLED = process.env.NEXT_PUBLIC_AUTH_ENABLED != '0';
export const BLUETOOTH_ENABLED = process.env.NEXT_PUBLIC_BLUETOOTH_ENABLED == '1';
export const DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string;
export const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string;
export const LOG_LEVEL = process.env.NEXT_PUBLIC_LOG_LEVEL || (ENV === 'production' ? 'error' : 'info');
export const DATADOG_ENABLED = process.env.NEXT_PUBLIC_DATADOG_ENABLED === '1';
export const VERSION = version;
export const MUI_LICENSE_KEY = process.env.NEXT_PUBLIC_MUI_LICENSE_KEY as string;
export const LAUNCH_DARKLY_CLIENT_SIDE_ID = process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID as string;
export const ENABLE_CALL_FEED_V2_FOR_EVERYBODY = process.env.NEXT_PUBLIC_ENABLE_CALL_FEED_V2_FOR_EVERYBODY == '1';
